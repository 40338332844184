import {Chat} from "@baton8/qroud-lib-repositories";
import {css} from "@emotion/react";
import {alpha, color, lineHeight, size} from "src/components/constants/constants";


interface Props {
  chat: Chat;
};

const styles = {
  root: css`
    ${lineHeight(1.4)}
  `,
  userName: css`
    max-inline-size: 50%;
    margin-inline-end: 0.4em;
    margin-block-start: -0.2em;
    margin-block-end: -0.4em;
    padding-block: 0.2em;
    padding-inline: 0.4em;
    font-size: ${size(3)};
    border-radius: ${size(1)};
    background-color: ${alpha(color("primary", 3), 0.2)};
    line-height: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  `
};

export const ChatView: React.FC<Props> = ({
  chat
}) => {
  return (
    <div css={styles.root}>
      <span css={styles.userName}>
        {chat.user.nickname}
      </span>
      <span>
        {chat.message}
      </span>
    </div>
  );
};
