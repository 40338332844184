import "dayjs/locale/ja";
import "simplebar";
import "simplebar/dist/simplebar.css";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import {
  CameraBoundary,
  Collision,
  FieldEntity,
  HideGhostsTrigger,
  HoldCompetitionTrigger,
  MoveTrigger,
  Npc,
  NpcMoveArea,
  RandomQuizTrigger,
  ShowMessageTrigger,
  SpawnPoint,
  TestFollowingNodeEntity
} from "./entities";
import {
  AutoDisconnector,
  AvatarDrawer,
  BottomOverlay,
  CreateSessionDrawer,
  DisconnectedCover,
  FollowingNodeContainer,
  HoldCompetitionDrawer,
  InputPasswordDialog,
  InputUserNameDialog,
  LoadingCover,
  LoginFormCover,
  Menu,
  MessageDialog,
  PrestartPane,
  QuizResultDialog,
  RandomQuizOverlay,
  ResultDialog,
  SelectSessionDrawer,
  ShareSessionLinkDialog,
  Toast
} from "./overlays";

dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.locale("ja");

export default {
  entities: [
    CameraBoundary,
    Collision,
    FieldEntity,
    HideGhostsTrigger,
    HoldCompetitionTrigger,
    MoveTrigger,
    Npc,
    NpcMoveArea,
    RandomQuizTrigger,
    ShowMessageTrigger,
    SpawnPoint,
    TestFollowingNodeEntity
  ],
  overlays: [
    AutoDisconnector,
    AvatarDrawer,
    BottomOverlay,
    CreateSessionDrawer,
    DisconnectedCover,
    FollowingNodeContainer,
    HoldCompetitionDrawer,
    InputPasswordDialog,
    InputUserNameDialog,
    LoadingCover,
    LoginFormCover,
    Menu,
    MessageDialog,
    PrestartPane,
    QuizResultDialog,
    RandomQuizOverlay,
    ResultDialog,
    SelectSessionDrawer,
    ShareSessionLinkDialog,
    Toast
  ],
  messages: {
    ja: require("./messages/ja.yaml"),
    en: require("./messages/en.yaml")
  }
};


export * from "./entities";
export * from "./components";

export * from "./overlays";
export * from "./ecs";

export * from "./modules/create";
export * from "./modules/format";
export * from "./modules/data";
export * from "./modules/subject";
export * from "./modules/translation";